import React, { useState, useEffect } from "react";
import "./Address.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "../component/Header";
import LoadingScreen from "react-loading-screen";
import { Buffer } from "buffer";
const Address = () => {
  var sha256 = require("js-sha256");
  const [address1, updateAddress1] = useState("");
  const [address2, updateAddress2] = useState("");
  const [city, updateCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [pincode, updatePincode] = useState("");
  let history = useNavigate();
  const location = useLocation();
  const API_URL = "https://liveapi.fastpay.direct";

  const backClick = () => {
    history("/orders/" + global.orderId);
  };

  const isEnable = () => {
    return address1 != "" && address2 != "" && city != "" && pincode != "";
  };

  const handleClick = () => {
    setLoading(true);
    global.address1 = address1;
    global.address2 = address2;
    global.city = city;
    global.pincode = pincode;
    createNewCustomer();
  };

  const cratePayment = async () => {
    console.log("cratePayment");
    const response = await fetch(`${API_URL}/api/orders/pay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        total: global.total,
        mid: global.tID,
        oid: global.orderId,
      }),
    });

    const res = await response.json();
    console.log(res);
    if (res.success == true) {
      setLoading(false);
      // console.log("hello")
      window.open(res.data.instrumentResponse.redirectInfo.url, "_self");
    } else {
      setLoading(false);
      console.log("error");
    }
  };

  const createNewCustomer = async () => {
    const address = {
      line1: global.address1,
      line2: global.address2,
      city: global.city,
      pincode: global.pincode,
    };
    const response = await fetch(`${API_URL}/api/orders/${global.orderId}`, {
      method: "PUT",
      body: JSON.stringify({
        address: address,
        isRequest: true,
        isPaid: false,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    console.log(res);
    setLoading(false);
    if (res.status == true) {
      let orderID = global.orderId;
      global.tID = orderID.concat(res.data.requestCount);
      console.log(global.tID);
      history("/pay");
      //Todo: hide for stipe payment
      // cratePayment();
    } else {
      console.log(res.message);
    }
  };

  return (
    <div>
      <Header />
      <div className="containerAddress">
        <p className="title">Your address</p>
        <p className="subTitle">Eg. Where you want the food to be delivered</p>

        <div className="address1">
          <p className="topText"> Address line 1 * </p>
          <input
            className="wideInput"
            type="text"
            onChange={(e) => {
              updateAddress1(e.target.value);
            }}
          />
        </div>
        <div className="address1">
          <p className="topText"> Address line 2 * </p>
          <input
            className="wideInput"
            type="text"
            onChange={(e) => {
              updateAddress2(e.target.value);
            }}
          />
        </div>
        <div className="address1">
          <p className="topText"> City / town * </p>
          <input
            className="wideInput"
            type="text"
            onChange={(e) => {
              updateCity(e.target.value);
            }}
          />
        </div>
        <div className="address1">
          <p className="topText"> Your PIN CODE * </p>
          <input
            className="wideInput"
            type="text"
            onChange={(event) => {
              const inputValue = event.target.value;
              // Remove any non-numeric characters
              const numericValue = inputValue.replace(/[^0-9]/g, "");
              // Update the input value
              event.target.value = numericValue;
              updatePincode(event.target.value);
            }}
          />
        </div>
        <div className="bottomBtn">
          <button
            className="backBtnAddress"
            type="button"
            onClick={() => {
              backClick();
            }}
          >
            Back
          </button>
          <button
            className={isEnable() ? "continueBtn" : "continueDisableBtn"}
            type="button"
            disabled={!isEnable()}
            onClick={() => {
              handleClick();
            }}
          >
            Continue to pay
          </button>
        </div>
        <LoadingScreen
          loading={loading}
          bgColor="#F25F2A"
          spinnerColor="#9ee5f8"
          textColor="#FFFFFF"
          logoSrc=""
          text="Confirming secure payment..."
        />
      </div>
    </div>
  );
};
export default Address;

// import React, {useState, useEffect} from 'react';
// import './Address.css'
// import{useNavigate, useLocation, useParams } from "react-router-dom";

// const Address = () => {

//     return(<div>
//          <div className="container">
//          <p className="title">Your order</p>
//      <p className="subTitle">Choose to pickup or get it delivered</p>
//          </div>
//     </div>)
// }
// export default Address;
