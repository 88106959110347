import React, { useState, useEffect } from "react";
import "./Orders.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "../component/Header";
import LoadingScreen from "react-loading-screen";
import { Buffer } from "buffer";
const Orders = () => {
  var sha256 = require("js-sha256");
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [total, setTotal] = useState("");
  const [gstPrice, setGstPrice] = useState("");
  const [deliverPrice, setDeliverPrice] = useState("");
  const [orderTotal, setOrderTotal] = useState("");
  let history = useNavigate();
  let { orderId } = useParams();
  const location = useLocation();
  const API_URL = "https://liveapi.fastpay.direct";
  const isEnablePayBtn = false;

  useEffect(() => {
    global.orderId = orderId;
    localStorage.setItem("orderId", orderId);
    setLoading(true);
    fetchMenuList();
  }, []);

  const fetchMenuList = async () => {
    console.log("Start " + orderId);
    const response = await fetch(`${API_URL}/api/orders/${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      global.fpOrderId = res.data.fpOrderId;
      global.orderDate = res.data.create_date;
      global.businessName = res.data.orders.businessName;
      global.phoneNumber = res.data.orders.phoneNumber;
      global.kichenNumber = res.data.orders.kichenNumber;
      global.total = res.data.orders.total;
      setProduct(res.data.orders.menu);
      setTotal(res.data.orders.total);
      setGstPrice(res.data.orders.gstPrice);
      setDeliverPrice(res.data.orders.deliverPrice);
      setOrderTotal(res.data.orders.orderTotal);
      setLoading(false);
      if (res.data.phonepe != null) {
        if (
          res.data.phonepe.success == true &&
          res.data.phonepe.code == "PAYMENT_SUCCESS"
        ) {
          history("/viewReceipt/" + global.orderId);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  const divStyle = {
    overflowY: "scroll",
    border: "1px solid red",
    width: "500px",
    float: "left",
    height: "500px",
    position: "relative",
  };

  const pickupbtnClick = () => {
    global.deliveryType = "pickup";
    history("/address", {
      state: location.state,
    });
  };

  const deliverybtnClick = async () => {
    global.deliveryType = "delivery";
    history("/address", {
      state: location.state,
    });
  };

  return (
    <div>
      <Header />
      {/* <img src={logo} style={{width: 100, height: 27, marginTop: 30, marginLeft: 55}} alt="logo" /> */}
      <div className="container">
        <p className="fastPayOrderTitleText">Your order</p>
        <p className="fastPayOrderSubTitleText">
          Please check order details before proceeding
        </p>
        <div className="scrollContainer">
          {product.map((item, index) => {
            return (
              <div className="fastpayitemContainer">
                <div className="menuLine" />
                <div className="fastpayitemrow">
                  <p className="fastpaydishName">{item.dishName}</p>
                  <p className="fastpaydishQuantity">
                    {Number(item.quantity) + 1}
                  </p>
                  <p className="fastpaydishPrice">₹ {item.price}</p>
                </div>
              </div>
            );
          })}
          <div className="menuLine" />
          <div className="price">
            <div className="fastpaypriceLine">
              <p className="fastpayCalCulationText">Total</p>
              <p className="fastpayCalCulationText">₹ {orderTotal}</p>
            </div>
            <div className="fastpaypriceLine">
              <p className="fastpayCalCulationText">GST at 5%</p>
              <p className="fastpayCalCulationText">₹ {gstPrice}</p>
            </div>
            <div className="fastpaypriceLine">
              <p className="fastpayCalCulationText">Delivery</p>
              <p className="fastpayCalCulationText">₹ {deliverPrice}</p>
            </div>
            <div className="fastpayTotalpriceLine">
              <p className="fastpayCalCulationBoldText">Order total</p>
              <p className="fastpayCalCulationBoldText">₹ {total}</p>
            </div>
          </div>
        </div>
        <div className="menuLine" />
        {/* <div className="bottomBtn"> */}
        {/* <button className="pickupBtn" type="button" onClick={()=>{pickupbtnClick()}}>Pickup</button> */}
        <p className="fastPayBusinessAddressText">
          {global.businessName} - will deliver your order.
        </p>
        <div className="menuLine" />
        <div className="fastpayBottomRow">
          <p className="fastPayGSTText">FastPay</p>
          <p className="fastPayGSTText">
            (Fully owned by Monetyze Technologies Pvt Ltd)
          </p>
          <div className="fastPayGSTROW">
            <p className="fastPayGSTText1">GST No.</p>
            <p className="fastPayGSTText">34AAMCM5316N1Z3</p>
          </div>
          <div className="fastPayGSTROW">
            <p className="fastPayGSTText1">CIN</p>
            <p className="fastPayGSTText">U72900PY2019PTC008396</p>
          </div>
        </div>
        <div className="menuLine" />
        <button
          className="deliveryBtn"
          type="button"
          onClick={() => {
            deliverybtnClick();
          }}
        >
          Enter delivery address
        </button>
        {/* </div> */}
        <LoadingScreen
          loading={loading}
          bgColor="#F25F2A"
          spinnerColor="#9ee5f8"
          textColor="#FFFFFF"
          logoSrc=""
          text="Fetching Order..."
        />
      </div>
    </div>
  );
};

export default Orders;
