import React, { useState, useEffect } from "react";
import "./KitchenMenu.css";
import LoadingScreen from "react-loading-screen";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import contacts from "../assets/images/contacts.png";
import poweredby from "../assets/images/poweredby.png";
const KitchenMenu = () => {
  const API_URL = "https://liveapi.fastpay.direct";
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectedButton, setSelectedButton] = useState(0);
  let { menuId } = useParams();
  let categoryNames = ["Starter", "Main", "Side", "Dessert"];
  useEffect(() => {
    global.menuId = menuId;
    setLoading(true);
    fetchMenuList();
  }, []);

  const fetchMenuList = async () => {
    console.log(`${API_URL}/api/business/menu/customer/${menuId}`);
    const response = await fetch(
      `${API_URL}/api/business/menu/customer/${menuId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      setLoading(false);
      global.businessName = res.data.businessName;
      global.phoneNumber = res.data.phone;
      setCategory(res.data.menu);
      console.log(res.data);
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  const CapsuleButton = ({ label, isSelected, onClick }) => {
    const buttonClassName = isSelected
      ? "kitchentapbutton selected"
      : "kitchentapbutton";

    return (
      <button className={buttonClassName} onClick={onClick}>
        {label}
      </button>
    );
  };

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
    onBuyClicked();
  };

  /** Launches payment request flow when user taps on buy button. */
  function onBuyClicked() {
    if (!window.PaymentRequest) {
      console.log("Web payments are not supported in this browser.");
      return;
    }

    // Create supported payment method.
    const supportedInstruments = [
      {
        supportedMethods: ["https://tez.google.com/pay"],
        data: {
          pa: "merchant-vpa@xxx",
          pn: "Merchant Name",
          tr: "1234ABCD", // Your custom transaction reference ID
          url: "https://url/of/the/order/in/your/website",
          mc: "1234", //Your merchant category code
          tn: "Purchase in Merchant",
        },
      },
    ];

    // Create order detail data.
    const details = {
      total: {
        label: "Total",
        amount: {
          currency: "INR",
          value: "10.01", // sample amount
        },
      },
      displayItems: [
        {
          label: "Original Amount",
          amount: {
            currency: "INR",
            value: "10.01",
          },
        },
      ],
    };

    // Create payment request object.
    let request = null;
    try {
      request = new PaymentRequest(supportedInstruments, details);
    } catch (e) {
      console.log("Payment Request Error: " + e.message);
      return;
    }
    if (!request) {
      console.log("Web payments are not supported in this browser.");
      return;
    }

    var canMakePaymentPromise = checkCanMakePayment(request);
    canMakePaymentPromise
      .then((result) => {
        // showPaymentUI(request, result);
      })
      .catch((err) => {
        console.log("Error calling checkCanMakePayment: " + err);
      });
  }

  // Global key for canMakepayment cache.
  const canMakePaymentCache = "canMakePaymentCache";

  /**
   * Check whether can make payment with Google Pay or not. It will check session storage
   * cache first and use the cache directly if it exists. Otherwise, it will call
   * canMakePayment method from PaymentRequest object and return the result, the
   * result will also be stored in the session storage cache for future usage.
   *
   * @private
   * @param {PaymentRequest} request The payment request object.
   * @return {Promise} a promise containing the result of whether can make payment.
   */
  function checkCanMakePayment(request) {
    // Check canMakePayment cache, use cache result directly if it exists.
    if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
      return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
    }

    // If canMakePayment() isn't available, default to assume the method is
    // supported.
    var canMakePaymentPromise = Promise.resolve(true);

    // Feature detect canMakePayment().
    if (request.canMakePayment) {
      canMakePaymentPromise = request.canMakePayment();
    }

    return canMakePaymentPromise
      .then((result) => {
        // Store the result in cache for future usage.
        sessionStorage[canMakePaymentCache] = result;
        return result;
      })
      .catch((err) => {
        console.log("Error calling canMakePayment: " + err);
      });
  }

  const tapButtonsRow = () => {
    return (
      <div style={{ display: "flex", marginLeft: 35 }}>
        <CapsuleButton
          label="All"
          isSelected={selectedButton === 0}
          onClick={() => handleButtonClick(0)}
        />
        <CapsuleButton
          label="Starter"
          isSelected={selectedButton === 1}
          onClick={() => handleButtonClick(1)}
        />
        <CapsuleButton
          label="Main"
          isSelected={selectedButton === 2}
          onClick={() => handleButtonClick(2)}
        />
        <CapsuleButton
          label="Side"
          isSelected={selectedButton === 3}
          onClick={() => handleButtonClick(3)}
        />
        <CapsuleButton
          label="Dessert"
          isSelected={selectedButton === 4}
          onClick={() => handleButtonClick(4)}
        />
      </div>
    );
  };

  const headerView = () => {
    return (
      <div className="kitchenHeader">
        <p className="kichenNumber">{global.businessName}</p>
        <div className="kichenNumberView">
          <img
            src={contacts}
            style={{
              width: 18,
              height: 18,
              marginLeft: "auto",
              color: "#A3D0B2",
            }}
            alt="logo"
          />
          <div style={{ marginLeft: 10 }}>
            <p className="kitchenCallText">
              Call{" "}
              {global.phoneNumber != null ? (
                <b>
                  {global.phoneNumber.slice(0, 4) +
                    " " +
                    global.phoneNumber.slice(4, 7) +
                    " " +
                    global.phoneNumber.slice(7, 10)}
                </b>
              ) : null}
            </p>
            {/* <p className="kichenNumber">{global.phoneNumber}</p> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="kitchencontainer">
      {headerView()}
      {tapButtonsRow()}
      {category
        .filter((item) => {
          if (selectedButton == 0) {
            return item.category != null;
          }
          return item.category == categoryNames[selectedButton - 1];
        })
        .map((item, index) => {
          return (
            <div className="kitchenItemRow">
              <div className="kitchenItemRowLine1">
                <p className="kitchenDishName">{item.dishName}</p>
                <p className="kitchenDishPrice">₹ {item.price}</p>
              </div>
              <div className="kitchenItemRowLine1">
                <img
                  className="kitchenDishImage"
                  src={item.foodURL}
                  alt="image"
                />
                <p className="kitchenDescription">{item.description}</p>
              </div>
              <div className="kitchenmenuLine" />
            </div>
          );
        })}
      <img src={poweredby} className="poweredBy" alt="logo" />
    </div>
  );
};

export default KitchenMenu;
