import React, { useState, useEffect } from "react";
import "./StripePage.css";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import fastpay from "../assets/images/fastpay.png";
import stripegst from "../assets/images/stripegst.png";
import secureIcon from "../assets/images/secure_icon.png";

const StripePage = () => {
  let history = useNavigate();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const API_URL = "https://liveapi.fastpay.direct";
  //   useEffect(() => {
  //     fetch("http://localhost:8080/config").then(async (r) => {
  //       const { publishableKey } = await r.json();
  //       setStripePromise(loadStripe(publishableKey));
  //     });
  //   }, []);

  useEffect(() => {
    console.log(global.total);
    fetch(API_URL + "/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: global.total,
      }),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []);

  const backClick = () => {};

  const updatePaymentStatusToFastpayServer = async (isSuccess) => {
    const response = await fetch(`${API_URL}/api/orders/stripecall`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        success: isSuccess,
        code: isSuccess ? "PAYMENT_SUCCESS" : "PAYMENT_FAILURE",
        message: "Payment is success",
        data: {
          merchantTransactionId: global.tID,
          transactionId: "",
          amount: "",
          paymentState: "",
          responseCode: "",
          paymentInstrument: {
            type: "Card payment",
            utr: "",
          },
        },
      }),
    });

    const res = await response.json();
    // console.log(res);
    if (res.success) {
      history("/viewReceipt/" + global.orderId);
    } else {
      console.log("error");
    }
  };

  const handleSubmit = async (e) => {
    // updatePaymentStatusToFastpayServer(false);
    // return;
    // history("/shareCode/" + global.orderId);
    // return;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "",
        },
      },
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
      alert(result.error.message);
      updatePaymentStatusToFastpayServer(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        // alert("payment success");
        updatePaymentStatusToFastpayServer(true);
      }
    }

    setIsProcessing(false);
  };

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "black",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "black",
      },
    },
  };

  return (
    <div className="StripeContainer">
      <img
        src={fastpay}
        style={{ width: 80, height: 22, marginLeft: 35, marginTop: 62 }}
        alt="logo"
      />
      <div>
        <p className="StrOrderTotal">Order total</p>
        <p className="StrOrderPrice">₹ {global.total}</p>
      </div>
      <div style={{ width: 290, marginLeft: 35, marginTop: 33 }}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>{" "}
      <img
        src={stripegst}
        style={{
          width: 292,
          height: 32,
          marginLeft: 35,
          marginTop: 131,
        }}
        alt="logo"
      />
      <div className="SecureBox">
        <img src={secureIcon} style={{ width: 12, height: 15 }} />
        <p className="SecureText">Secure payments by Stripe</p>
      </div>
      <div className="StrbottomBtn">
        <button
          className="StrbackBtnAddress"
          type="button"
          onClick={() => {
            backClick();
          }}
        >
          Back
        </button>
        <button
          className={stripe ? "StrcontinueBtn" : "StrcontinueDisableBtn"}
          type="button"
          disabled={!stripe}
          onClick={() => {
            handleSubmit();
          }}
        >
          Pay ₹ {global.total}
        </button>
      </div>
    </div>
  );
};

export default StripePage;
