import React, { useState, useEffect } from "react";
import "./ShareCode.css";
import cooking from "../assets/images/cooking.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "../component/Header";
import LoadingScreen from "react-loading-screen";
const ShareCode = () => {
  var sha256 = require("js-sha256");
  let history = useNavigate();
  const [loading, setLoading] = useState(false);
  let { orderId } = useParams();
  const [isPaid, setIsPaid] = useState(false);
  const API_URL = "https://liveapi.fastpay.direct";
  const viewReceiptBtn = () => {
    history("/viewReceipt/" + global.orderId);
  };
  const handleSubmit = () => {
    // history('/viewReceipt');
  };

  useEffect(() => {
    global.orderId = orderId;
    fetchMenuList();
    console.log("share code");
    //  checkAPIStatus()
  }, []);

  const checkStaus = async () => {
    const response = await fetch(API_URL + "/api/orders/pay/status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mid: global.tID,
      }),
    });

    const res = await response.json();
    // console.log(res)
    if (res.success == true) {
      setLoading(false);
      setIsPaid(true);
      // console.log("hello")
    } else {
      setLoading(false);
      console.log("error");
    }
  };

  const checkAPIStatus = async () => {
    const url =
      "https://api-preprod.phonepe.com/apis/merchant-simulator/pg/v1/status/PGTESTPAYUAT/FASTPAY10001";
    const xVer =
      sha256(
        "/pg/v1/status/PGTESTPAYUAT/FASTPAY10001" +
          "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399"
      ) + "###1";
    console.log(xVer);
    const options = {
      method: "GET",
      // mode: 'no-cors',
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-VERIFY": xVer,
        "X-MERCHANT-ID": "PGTESTPAYUAT",
      },
    };
    fetch("https://cors-anywhere.herokuapp.com/" + url, options)
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        //  console.log(response)
        if (response.success == true) {
          setIsPaid(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const fetchMenuList = async () => {
    setLoading(true);
    console.log("Start " + orderId);
    const response = await fetch(`${API_URL}/api/orders/${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    // console.log(res)
    if (res.status == true) {
      global.tID = res.data.merchantTransactionId;
      global.fpOrderId = res.data.fpOrderId;
      global.orderDate = res.data.create_date;
      global.businessName = res.data.orders.businessName;
      global.phoneNumber = res.data.orders.phoneNumber;
      global.kichenNumber = res.data.orders.kichenNumber;
      // setProduct(res.data.orders.menu)
      // setTotal(res.data.orders.total)
      // setGstPrice(res.data.orders.gstPrice)
      // setDeliverPrice(res.data.orders.deliverPrice)
      // setOrderTotal(res.data.orders.orderTotal)
      //  console.log("ithu " + res.data.orders.isPaid)
      //Todo: stripe payment
      // checkStaus();
      setIsPaid(true);
      setLoading(false);
      // if(res.data.orders.isPaid == true){
      //   console.log("res.message")
      //   history('/shareCode');
      //   setLoading(false)
      // }
      // }else{
      //   setLoading(false)
      //     console.log(res.message)
      // }
    }
  };

  return (
    <div>
      <Header />
      {isPaid ? (
        <div className="container">
          <div className="titleRow">
            <p className="shareTitle">{global.businessName}</p>
            <p className="date">{global.orderDate}</p>
            <p
              className="receipt"
              onClick={() => {
                viewReceiptBtn();
              }}
            >
              View receipt
            </p>
          </div>
          <div className="secondRow">
            {/* <div className="shareCodeBox">
                 <p className="titleCode">Share with rider</p>
                 <p className="code">685080</p>
             </div> */}
            <div className="cookdiv">
              <p className="cookNow">Cooking now!</p>
              <img
                src={cooking}
                style={{ width: 45, height: 45, marginLeft: "auto" }}
                alt="logo"
              />
            </div>
          </div>
          <div className="topLines" />
          <p
            style={{
              marginTop: 150,
              fontSize: 20,
              fontWeight: 300,
              textAlign: "center",
            }}
          >
            🎉 You’ve earned a cashback!
          </p>
          <button
            className="claimBtn"
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Claim your cashback! 💰
          </button>
        </div>
      ) : (
        <div>
          <p
            style={{
              marginTop: 150,
              fontSize: 20,
              fontWeight: 300,
              textAlign: "center",
            }}
          >
            Payment Failed
          </p>
        </div>
      )}
      <LoadingScreen
        loading={loading}
        bgColor="#F25F2A"
        spinnerColor="#9ee5f8"
        textColor="#FFFFFF"
        logoSrc=""
        text="Loading..."
      />
    </div>
  );
};
export default ShareCode;
