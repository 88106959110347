import React, { useState, useEffect } from "react";
import "./ViewReceipt.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { TaskTimer } from "tasktimer";
// import Header from '../component/Header';
import LoadingScreen from "react-loading-screen";
import fastpay from "../assets/images/fastpay.png";
import close from "../assets/images/close.png";
import bag from "../assets/images/bag.png";
import fail from "../assets/images/fail.png";
import FadeLoader from "react-spinners/FadeLoader";

const ViewReceipt = () => {
  const timer = new TaskTimer(1000);
  let intervalID = null;
  const [product, setProduct] = useState([]);
  const [total, setTotal] = useState("");
  const [gstPrice, setGstPrice] = useState("");
  const [orderTotal, setOrderTotal] = useState("");
  const [deliverPrice, setDeliverPrice] = useState("");
  const [isPaid, setIsPaid] = useState("success");
  const [pendingTime, setPendingTime] = useState("00:00");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  let history = useNavigate();
  let { orderId } = useParams();
  const location = useLocation();
  const API_URL = "https://liveapi.fastpay.direct";
  const isEnablePayBtn = false;

  useEffect(() => {
    global.orderId = orderId;
    setLoading(true);
    fetchMenuList();
    // You can also execute some code on each tick... (every 1000 ms)
    //Todo: payment fail
    // timer.on("tick", () => {
    //   setPendingTime(millisToMinutesAndSeconds(60000 - timer.time.elapsed));
    //   if (timer.tickCount == 20) {
    //     pendingCallAPI();
    //   } else if (timer.tickCount > 20 && timer.tickCount % 3 == 0) {
    //     pendingCallAPI();
    //   }
    //   if (timer.tickCount >= 60) {
    //     timer.stop();
    //     global.isPendingTried = true;
    //     setIsPaid("PAYMENT_ERROR");
    //     updatePendingAttempt();
    //   }
    // });
  }, []);

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  const checkStaus = async () => {
    const response = await fetch(
      `https://fastpay-n95r.onrender.com/api/orders/pay/status`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mid: global.tID,
        }),
      }
    );

    const res = await response.json();
    console.log(res);
    if (res.success == true) {
      setLoading(false);
      if (global.isPendingTried == true && res.code == "PAYMENT_PENDING") {
        setIsPaid("PAYMENT_ERROR");
      } else {
        setIsPaid(res.code);
        if (res.code == "PAYMENT_PENDING") {
          timer.start();
        }
      }
    } else {
      setLoading(false);
      if (global.isPendingTried == true && res.code == "PAYMENT_PENDING") {
        setIsPaid("PAYMENT_ERROR");
      } else {
        setIsPaid(res.code);
        if (res.code == "PAYMENT_PENDING") {
          timer.start();
        }
      }
    }
  };

  const pendingCallAPI = async () => {
    const response = await fetch(
      `https://fastpay-n95r.onrender.com/api/orders/pay/status`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mid: global.tID,
        }),
      }
    );
    const res = await response.json();
    console.log(res);
    if (res.success == true) {
      setLoading(false);
      if (global.isPendingTried == false) {
        setIsPaid(res.code);
      }
    }
  };

  const fetchMenuList = async () => {
    const response = await fetch(`${API_URL}/api/orders/${global.orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      setResult(res.data);
      global.total = res.data.orders.total;
      global.tID = res.data.merchantTransactionId;
      global.isPendingTried = res.data.isPendingTried;
      setProduct(res.data.orders.menu);
      setTotal(res.data.orders.total);
      setGstPrice(res.data.orders.gstPrice);
      setDeliverPrice(res.data.orders.deliverPrice);
      setOrderTotal(res.data.orders.orderTotal);
      // if (res.data.phonepe != null) {
      //   if (res.data.phonepe.success == true) {

      setLoading(false);
      if (res.data.phonepe != null) {
        setIsPaid(res.data.phonepe.code);
      }
      //     setIsPaid(res.data.phonepe.code)
      //     if (res.data.phonepe.code == "PAYMENT_PENDING") {
      //       timer.start()
      //     }
      //   } else {
      //     setLoading(false)
      //     setIsPaid(res.data.phonepe.code)
      //     if (res.data.phonepe.code == "PAYMENT_PENDING") {
      //       timer.start()
      //     }
      //   }
      // } else {
      //Todo: Stripe
      // checkStaus();
      // }
    } else {
      setLoading(false);
      // console.log(res.message)
    }
  };

  const divStyle = {
    overflowY: "scroll",
    border: "1px solid red",
    width: "500px",
    float: "left",
    height: "500px",
    position: "relative",
  };

  const receiptView = () => {
    return (
      <div className="main_header">
        <header className="header">
          <div className="headerContainRec">
            {/* <p className="headerName">{global.businessName}</p> */}
            <img
              src={fastpay}
              style={{ width: 80, height: 22, marginRight: "auto" }}
              alt="logo"
            />
            <img
              src={close}
              style={{ width: 12, height: 12, marginLeft: "auto" }}
              alt="logo"
              onClick={cancelBtn}
            />
          </div>
          <div className="order_date">
            <div className="order_detail">
              <div className="order_hotel">
                <p
                  className="order_hotel_name"
                  style={{ fontWeight: "bold", fontSize: 18 }}
                >
                  Thank you!
                </p>{" "}
                <br />
                <p
                  className="order_hotel_name"
                  style={{ fontWeight: "400", fontSize: 14 }}
                >
                  {result != null
                    ? result.orders.businessName + " " + "receipt"
                    : ""}
                </p>
              </div>
              <div className="bill_order_date">
                <p className="order_date_id">
                  {result != null ? result.create_date : ""}
                </p>{" "}
                <br />
                <p className="order_date_id" style={{ fontWeight: "bold" }}>
                  Order ID - {result != null ? result.fpOrderId : ""}
                </p>
              </div>
            </div>
            <div className="logo_right_vector">
              <img className="logo_right" src={bag} alt="Vector" />
            </div>
          </div>
        </header>
        {/* <Header /> */}
        {/* <img src={logo} style={{width: 100, height: 27, marginTop: 30, marginLeft: 55}} alt="logo" /> */}
        <div className="container">
          {/* <div className="kitchenRow">
          <p className="kichenName">Royal Spices</p>
          <p className="orderId">Order ID - FH1100001</p>
        </div> */}
          <div className="scrollContainer">
            {product.map((item, index) => {
              return (
                <div className="fastpayitemContainer">
                  <div className="menuLine" />
                  <div className="fastpayitemrow">
                    <p className="fastpaydishName">{item.dishName}</p>
                    <p className="fastpaydishQuantity">
                      {Number(item.quantity) + 1}
                    </p>
                    <p className="fastpaydishPrice">₹ {item.price}</p>
                  </div>
                </div>
              );
            })}
            <div className="menuLine" />
            <div className="price">
              <div className="fastpaypriceLine">
                <p className="fastpayCalCulationText">Total</p>
                <p className="fastpayCalCulationText">₹ {orderTotal}</p>
              </div>
              <div className="fastpaypriceLine">
                <p className="fastpayCalCulationText">GST at 5%</p>
                <p className="fastpayCalCulationText">₹ {gstPrice}</p>
              </div>
              <div className="fastpaypriceLine">
                <p className="fastpayCalCulationText">Delivery</p>
                <p className="fastpayCalCulationText">₹ {deliverPrice}</p>
              </div>
              <div className="fastpayTotalpriceLine">
                <p className="fastpayCalCulationBoldText">Order total</p>
                <p className="fastpayCalCulationBoldText">₹ {total}</p>
              </div>
              <div className="menuLine" />
              <div style={{ height: 70, marginTop: 9 }}>
                <p style={{ fontSize: 12, fontWeight: "bold", margin: 0 }}>
                  Delivery to
                </p>
                <p
                  style={{
                    fontSize: 12,
                    margin: 0,
                    lineheight: 19,
                    marginTop: 6,
                  }}
                >
                  {result != null
                    ? result.address.line1 + ", " + result.address.line2
                    : ""}
                </p>
                <p
                  style={{
                    fontSize: 12,
                    margin: 0,
                    lineheight: 19,
                    marginTop: 6,
                  }}
                >
                  {result != null
                    ? result.address.city + ", " + result.address.pincode
                    : ""}
                </p>
              </div>
              <div className="menuLine" />
              <p className="fastPayBusinessAddressText">
                {global.businessName} - will deliver your order.
              </p>
              <div className="menuLine" />
              <div className="fastpayBottomRow">
                <p className="fastPayGSTText">FastPay</p>
                <p className="fastPayGSTText">
                  (Fully owned by Monetyze Technologies Pvt Ltd)
                </p>
                <div className="fastPayGSTROW">
                  <p className="fastPayGSTText1">GST No.</p>
                  <p className="fastPayGSTText">34AAMCM5316N1Z3</p>
                </div>
                <div className="fastPayGSTROW">
                  <p className="fastPayGSTText1">CIN</p>
                  <p className="fastPayGSTText">U72900PY2019PTC008396</p>
                </div>
              </div>
              <div className="menuLine" />
              <button
                className="claimBtn"
                type="button"
                style={{ marginTop: 70 }}
              >
                Claim your cashback! 💰
              </button>
            </div>
          </div>
          {/* <button className="cancelBtn" type="button" onClick={() => { cancelBtn() }}>Back</button> */}
        </div>
      </div>
    );
  };

  const cancelBtn = () => {
    history("/shareCode/" + global.orderId);
  };

  const failcancelBtn = () => {
    history("/orders/" + global.orderId);
  };

  const cratePayment = async () => {
    console.log("cratePayment");
    const response = await fetch(`${API_URL}/api/orders/pay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        total: global.total,
        mid: global.tID,
        oid: global.orderId,
      }),
    });

    const res = await response.json();
    console.log(res);
    if (res.success == true) {
      setLoading(false);
      // console.log("hello")
      window.open(res.data.instrumentResponse.redirectInfo.url, "_self");
    } else {
      setLoading(false);
      console.log("error");
    }
  };

  const createNewCustomer = async () => {
    setLoading(true);
    const response = await fetch(`${API_URL}/api/orders/${global.orderId}`, {
      method: "PUT",
      body: JSON.stringify({
        isRequest: true,
        isPaid: false,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      let orderID = global.orderId;
      global.tID = orderID.concat(res.data.requestCount);
      console.log(global.tID);
      // cratePayment();
      history("/pay");
    } else {
      console.log(res.message);
    }
  };

  const updatePendingAttempt = async () => {
    setLoading(true);
    const response = await fetch(`${API_URL}/api/orders/${global.orderId}`, {
      method: "PUT",
      body: JSON.stringify({
        isPendingTried: true,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    setLoading(false);
    if (res.status == true) {
      global.isPendingTried = true;
    } else {
      console.log(res.message);
    }
  };

  const failurePayment = () => {
    return (
      <div className="container">
        <div>
          <div className="headerContainFail">
            {/* <p className="headerName">{global.businessName}</p> */}
            <img
              src={fastpay}
              style={{ width: 80, height: 22, marginRight: "auto" }}
              alt="logo"
            />
            <img
              src={close}
              style={{ width: 12, height: 12, marginLeft: "auto" }}
              alt="logo"
              onClick={failcancelBtn}
            />
          </div>
          <img
            src={fail}
            style={{
              width: 35,
              height: 32,
              marginRight: "auto",
              marginTop: 100,
              marginBottom: 20,
            }}
            alt="logo"
          />
          <p
            style={{
              margin: 0,
              fontSize: 24,
              fontWeight: 700,
              paddingBottom: 10,
            }}
          >
            Payment error ⚡{" "}
          </p>
          <p style={{ marginTop: 0, fontSize: 12, fontWeight: 400 }}>
            We were not able to process the payment and you were not charged for
            this transaction
          </p>
          <p
            style={{
              marginTop: 40,
              fontSize: 14,
              fontWeight: 400,
              paddingBottom: 50,
            }}
          >
            It’s safe to try paying again
          </p>
          <button
            className="claimBtn"
            type="button"
            onClick={() => {
              createNewCustomer();
            }}
          >
            Let’s try again
          </button>
        </div>
      </div>
    );
  };

  const pendingPayment = () => {
    return (
      <div className="container">
        <div className="headerContainFail">
          {/* <p className="headerName">{global.businessName}</p> */}
          <img
            src={fastpay}
            style={{ width: 80, height: 22, marginRight: "auto" }}
            alt="logo"
          />
          {/* <img src={close} style={{ width: 12, height: 12, marginLeft: "auto" }} alt="logo" onClick={cancelBtn}/> */}
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 300,
            textAlign: "center",
          }}
        >
          <FadeLoader
            color="black"
            cssOverride={{ margin: "0 auto", marginTop: 100, marginBottom: 40 }}
            size={50}
          />
          {/* <img src={fail} style={{width: 35, height: 32, marginTop: 100, marginBottom: 20, alignSelf: "center"}} alt="logo" /> */}
          <p
            style={{
              margin: 0,
              fontSize: 24,
              fontWeight: 700,
              paddingBottom: 10,
              alignSelf: "center",
            }}
          >
            Payment pending 👋
          </p>
          <p
            style={{
              marginTop: 0,
              fontSize: 12,
              fontWeight: 400,
              alignSelf: "center",
            }}
          >
            It’s taking longer than usual, please don’t move away from this
            screen
          </p>
          <p
            style={{
              marginTop: 40,
              fontSize: 14,
              fontWeight: 700,
              paddingBottom: 50,
              alignSelf: "center",
            }}
          >
            {pendingTime}
          </p>
        </div>
      </div>
    );
  };

  const paymentCases = () => {
    if (loading) {
      return <div className="container" />;
    }
    if (isPaid == "PAYMENT_SUCCESS") {
      return receiptView();
    } else if (isPaid == "PAYMENT_PENDING") {
      return pendingPayment();
    } else {
      return failurePayment();
    }
    return failurePayment();
  };

  return (
    <div>
      {paymentCases()}
      <LoadingScreen
        loading={loading}
        bgColor="#F25F2A"
        spinnerColor="#9ee5f8"
        textColor="#FFFFFF"
        logoSrc=""
        text="Loading..."
      />
    </div>
  );
};

export default ViewReceipt;
